<template>
  <div>
    <div class="banner">
      <el-carousel indicator-position="none" arrow="never">
        <el-carousel-item height="650" v-for="item in bannerImg" :key="item">
          <img :src="item" alt="" />
        </el-carousel-item>
      </el-carousel>
    </div>
    <h1>{{ name }}</h1>
    <div class="contBox">
      <div
        class="item"
        v-for="item in contBox"
        :key="item.id"
        @click="itemUrl(item)"
      >
        <img :src="item.projectDetailImg" :alt="item.projectDetailImg" />
        <h3>
          <el-button type="text">
            {{ item.projectName }}
          </el-button>
        </h3>
        <p>{{ item.describe }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { findBannerImg, findItem } from '../../api/parentPage'
export default {
  data() {
    return {
      bannerImg: '',
      typeId: '',
      contBox: [],
      name: ''
    }
  },
  created() {
    this.typeId = this.$route.params.type
    this.name = this.$route.params.name
    let typeId = this.$route.params.pId
    if (typeId) {
      this.initOther(typeId)
    }
    this._initData()
  },
  watch: {
    //监听相同路由下参数变化的时候，从而实现异步刷新
    $route(to, from) {
      //做一些路由变化的响应
      //打开加载动画
      this.loading = true
      /** 初始化其他数据 */
      let typeId = this.$route.params.pId
      this.typeId = this.$route.params.type
      //重新获取数据
      this._initData()
      if (typeId) {
        this.initOther(typeId)
      }
    }
  },
  methods: {
    _initData() {
      // 查询banner图片
      findBannerImg({ id: this.typeId }).then((res) => {
        if (res.status == 200) {
          this.bannerImg = res.data.banner.split(',')
        }
      })
      // 查询菜单对应信息
      findItem({ data: { groupId: this.typeId } }).then((res) => {
        if (res.status == 200) {
          console.log('contBox', res.data.rows)
          this.contBox = []
          res.data.rows.forEach((item) => {
            if (item.isShow) {
              // 如果传的不是三张 就自己判断一下
              item.projectDetailImg = item.projectDetailImg.split(',')[0]
              this.contBox.push(item)
            }
          })
        }
      })
    },
    itemUrl(item) {
      // this.$router.push({ path: `/subPage/${this.typeId}/${item.id}` });
      this.$router.push({
        path: `/details/${this.name}/${this.typeId}/${item.id}`
      })
    }
  }
}
</script>

<style lang="less" scoped>
.banner {
  width: 100%;
  text-align: center;
  ::v-deep .el-carousel > .el-carousel__container {
    height: 650px !important;
    .el-carousel__item {
      width: 100%;
      text-align: center;
      > img {
        width: 100%;
        height: 650px;
        object-fit: cover;
      }
    }
  }
}
h1 {
  margin: 100px 0;
}
.contBox {
  width: 1200px;
  padding: 0 0px 100px;
  margin: auto;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  overflow: hidden;
  cursor: pointer;
  .item {
    width: 340px;
    margin: 0 20px 20px 0;
    margin-right: 50px;
  }
  > div {
    // width: 360px;
    // overflow: hidden;
    text-align: center;
    box-sizing: border-box;
    margin-bottom: 50px;
    > img {
      border-radius: 15px;
      width: 340px;
      height: 260px;
      border: 1px solid #ccc;
    }
    > h3 {
      margin: 10px 0px;
      > button {
        font-weight: 400;
        font-size: 18px;
        color: rgba(0, 0, 0, 0.6);
        text-align: start;
        padding: 0;

        &:hover {
          color: rgba(0, 0, 0, 1);
        }
        ::v-deep span {
          display: inline-block;
          width: 360px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
    > p {
      width: 340px;
      margin: 0;
      color: #999;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      text-align: start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      transform: all 0.3s linear;
      // display: -webkit-box; //对象作为弹性伸缩盒子模型显示
      // overflow: hidden; //溢出隐藏
      // -webkit-box-orient: vertical; //设置伸缩盒子对象的子元素的排列方式
      // -webkit-line-clamp: 2; //设置 块元素包含的文本行数
      &:hover {
        color: #333;
      }
    }
  }
}
</style>
